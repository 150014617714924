import { useRouter } from "next/router";
const useOneLink = (url?: string) => {
  const router = useRouter();
  const oneLinkParam = router?.query?.onelink;
  if (oneLinkParam) {
    return `https://reku.onelink.me/5mL7/${oneLinkParam}`;
  }
  if (url) {
    return `https://reku.onelink.me/5mL7/${url}`;
  }
  return "https://reku.onelink.me/5mL7/web";
};
export default useOneLink;