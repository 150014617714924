import { useEffect } from "react";
import Image, { StaticImageData } from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Container from "@components/v4/Container";
import Portfolio from "../images/portfolio.webp";
import Referral from "../images/referral.webp";
import Pocket from "../images/pocket.webp";
interface CardProps {
  id: number;
  title: string;
  subtitle: string;
  color: string;
  image: StaticImageData;
}
const Card = ({
  id,
  title,
  subtitle,
  color,
  image
}: CardProps) => {
  const router = useRouter();
  const isEnglish = router.locale === "en";
  const getColor = () => {
    if (color === "teal") return "bg-[#EDF7F7] dark:bg-dark-background-solid-teal";
    return "bg-[#F5F0FE] dark:bg-dark-background-solid-purple";
  };
  const getPosition = () => {
    switch (id) {
      case 2:
        return "translate-y-[20px]";
      case 3:
        return "translate-y-[40px]";
      default:
        return "translate-y-[0px]";
    }
  };
  return <div className={`advantage-${id} sticky ${isEnglish ? "top-[103px]" : "top-[136px]"} xl:top-[176px] ${getPosition()} h-[500px] flex flex-col-reverse xl:flex-row items-center rounded-[24px] ${getColor()} transition-transform duration-200 ease-linear origin-top`}>
            <div className='flex-1 px-4 pb-6 pt-4 xl:py-[56px] xl:px-[120px] space-y-3 xl:space-y-4'>
                <h1 className='[@media(max-width:340px)]:text-heading-md text-heading-lg xl:text-heading-2xl text-text-neutral-strong dark:text-dark-text-neutral-strong'>
                    {title}
                </h1>
                <p className='text-body-md xl:text-body-lg text-text-neutral-main dark:text-dark-text-neutral-main'>
                    {subtitle}
                </p>
            </div>
            <div className='flex-1 flex justify-center items-end'>
                <Image src={image.src} alt='Iphone mockup screen' width={461} height={500} className='[@media(max-width:340px)]:h-[252px] [@media(max-width:340px)]:w-[217px] h-[280px] w-[241px] xl:h-[500px] xl:w-[461px]' />
            </div>
        </div>;
};
const ManyAdvantages = () => {
  const {
    t
  } = useTranslation("home");
  const router = useRouter();
  const advantages = [{
    id: 1,
    title: t("many_advantages.portfolio.title"),
    subtitle: t("many_advantages.portfolio.description"),
    color: "teal",
    image: Portfolio
  }, {
    id: 2,
    title: t("many_advantages.referral.title"),
    subtitle: t("many_advantages.referral.description"),
    color: "purple",
    image: Referral
  }, {
    id: 3,
    title: t("many_advantages.sam.title"),
    subtitle: t("many_advantages.sam.description"),
    color: "teal",
    image: Pocket
  }];
  useEffect(() => {
    const container = document.querySelector<HTMLElement>(".many-advantage-container");
    const headerTitle = document.querySelector<HTMLElement>(".header-title");
    const advantage1 = document.querySelector<HTMLElement>(".advantage-1");
    const advantage2 = document.querySelector<HTMLElement>(".advantage-2");
    const advantage3 = document.querySelector<HTMLElement>(".advantage-3");
    const handleScroll = () => {
      if (container && advantage1 && advantage2 && advantage3 && headerTitle) {
        const containerRect = container.getBoundingClientRect().top || 0;
        const scaleSize = 1000;
        const offset1 = containerRect + 400;
        const offset2 = containerRect + 400 + 550;
        if (offset1 < 0 && offset1 > -100) {
          const scale = offset1 / scaleSize;
          advantage1.style.scale = `${1 - Math.abs(scale)}`;
        }
        if (offset1 > 0) {
          advantage1.style.scale = `1`;
        }
        if (offset2 < 0 && offset2 > -100) {
          const scale = offset2 / scaleSize;
          advantage2.style.scale = `${1 - Math.abs(scale)}`;
          advantage1.style.scale = `${0.9 - Math.abs(scale)}`;
        }
        if (offset2 > 0) {
          advantage2.style.scale = `1`;
          headerTitle.style.translate = `0 0px`;
        }
        if (offset2 < -100) {
          headerTitle.style.translate = `0 ${offset2 + 100}px`;
        }
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [router.locale]);
  return <div className='h-[1740px] xl:h-[1840px] bg-background-card-main dark:bg-dark-background-card-main'>
            <Container className='many-advantage-container pt-12 pb-10 xl:py-20 space-y-8 xl:space-y-[60px]'>
                <h1 className='header-title sticky top-10 xl:top-20 text-heading-lg xl:text-heading-xl text-text-neutral-strong dark:text-dark-text-neutral-strong text-center'>
                    {t("many_advantages.title")}
                </h1>
                <div className='flex flex-col gap-4 xl:gap-6'>
                    {advantages.map(({
          id,
          title,
          subtitle,
          color,
          image
        }) => <Card key={id} id={id} title={title} subtitle={subtitle} color={color} image={image} />)}
                </div>
            </Container>
        </div>;
};
export default ManyAdvantages;