import React from "react";
const FeatureStock: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none' viewBox='0 0 40 40'>
            <path fill='#6F4CB3' fillRule='evenodd' d='M6.667 5a1.667 1.667 0 10-3.333 0v30c0 .92.746 1.667 1.667 1.667h30a1.667 1.667 0 000-3.334H7.667a1 1 0 01-1-1V5zm20 6.667c0 .92.746 1.666 1.667 1.666h2.643l-5.976 5.977-2.879-2.88-.027-.026c-.14-.14-.308-.309-.468-.444a2.512 2.512 0 00-.854-.493 2.5 2.5 0 00-1.545 0 2.512 2.512 0 00-.854.493c-.16.136-.327.303-.467.444l-.028.027-7.39 7.39a1.667 1.667 0 102.357 2.357L20 19.024l2.878 2.878.028.028c.14.14.308.308.467.443.185.157.466.367.854.493a2.5 2.5 0 001.545 0c.388-.126.669-.335.854-.493.16-.135.327-.303.468-.443l.027-.028 6.212-6.212v2.643a1.667 1.667 0 003.333 0v-6.666c0-.92-.746-1.667-1.666-1.667h-6.667c-.92 0-1.667.746-1.667 1.667z' clipRule='evenodd' />
            <mask id='mask0_2771_31589' style={{
      maskType: "alpha"
    }} width='34' height='34' x='3' y='3' maskUnits='userSpaceOnUse'>
                <path fill='#000' fillRule='evenodd' d='M6.667 5a1.667 1.667 0 10-3.333 0v30c0 .92.746 1.667 1.667 1.667h30a1.667 1.667 0 000-3.334H7.667a1 1 0 01-1-1V5zm20 6.667c0 .92.746 1.666 1.667 1.666h2.643l-5.976 5.977-2.879-2.88-.027-.026c-.14-.14-.308-.309-.468-.444a2.512 2.512 0 00-.854-.493 2.5 2.5 0 00-1.545 0 2.512 2.512 0 00-.854.493c-.16.136-.327.303-.467.444l-.028.027-7.39 7.39a1.667 1.667 0 102.357 2.357L20 19.024l2.878 2.878.028.028c.14.14.308.308.467.443.185.157.466.367.854.493a2.5 2.5 0 001.545 0c.388-.126.669-.335.854-.493.16-.135.327-.303.468-.443l.027-.028 6.212-6.212v2.643a1.667 1.667 0 003.333 0v-6.666c0-.92-.746-1.667-1.666-1.667h-6.667c-.92 0-1.667.746-1.667 1.667z' clipRule='evenodd' />
            </mask>
            <g fill='#fff' fillRule='evenodd' clipRule='evenodd' mask='url(#mask0_2771_31589)'>
                <path fillOpacity='0.25' d='M3.333 58.667c10.309 0 18.666-8.358 18.666-18.667 0-10.31-8.357-18.667-18.666-18.667-10.31 0-18.667 8.358-18.667 18.667 0 10.31 8.357 18.667 18.667 18.667zm0 8c14.727 0 26.666-11.94 26.666-26.667C30 25.272 18.06 13.333 3.333 13.333c-14.728 0-26.667 11.94-26.667 26.667 0 14.727 11.94 26.667 26.667 26.667z' />
                <path fillOpacity='0.5' d='M3.332 48a8 8 0 100-16 8 8 0 000 16zm0 8c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16z' />
            </g>
        </svg>;
};
export default FeatureStock;