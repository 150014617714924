import React from "react";
const FeatureCrypto: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' {...props}>
            <path fill='#1BA7AC' fillRule='evenodd' d='M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m5.481-10.703L12.296 7.11 7.11 12.297l5.186 5.185z' clipRule='evenodd' />
            <mask id='a' style={{
      maskType: "alpha"
    }} width='22' height='22' x='1' y='1' maskUnits='userSpaceOnUse'>
                <path fill='#D9D9D9' fillRule='evenodd' d='M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m5.481-10.703L12.296 7.11 7.11 12.297l5.186 5.185z' clipRule='evenodd' />
            </mask>
            <g fill='#fff' fillRule='evenodd' clipRule='evenodd' mask='url(#a)'>
                <path fillOpacity='0.25' d='M13 37.2c6.186 0 11.2-5.014 11.2-11.2S19.186 14.8 13 14.8 1.8 19.814 1.8 26 6.814 37.2 13 37.2m0 4.8c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16' />
                <path fillOpacity='0.5' d='M13 30.8a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6m0 4.8a9.6 9.6 0 009.6-9.6 9.6 9.6 0 10-19.2 0 9.6 9.6 0 009.6 9.6' />
            </g>
        </svg>;
};
export default FeatureCrypto;