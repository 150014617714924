import Head from "@components/Head";
import CTADownLoad from "@components/CTADownLoad";
import Hero from "./components/Hero";
import Service from "./components/Service";
import MultiplePortfolio from "./components/MultiplePortfolio";
import ManyAdvantages from "./components/ManyAdvantages";
import Secure from "./components/Secure";
import OfficialPartners from "./components/OfficialPartners";
import Testimonies from "./components/Testimonies";
import LearningHub from "./components/LearningHub";
const Home = () => <main className='reku-new-theme bg-background-neutral-main dark:bg-dark-background-neutral-main xl:pt-[72px]'>
        <Head />
        <Hero />
        <Service />
        <MultiplePortfolio />
        <ManyAdvantages />
        <Secure />
        <OfficialPartners />
        <Testimonies />
        <LearningHub />
        <CTADownLoad />
    </main>;
export default Home;