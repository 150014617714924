import Image from "next/image";
import Marquee from "react-fast-marquee";
import { useTranslation } from "next-i18next";
import { useMediaQuery } from "react-responsive";
import Container from "@components/v4/Container";
import ACV from "../images/acv.webp";
import Coinbase from "../images/Coinbase.webp";
import Fireblocks from "../images/fireblocks.webp";
import Skystar from "../images/skystar.webp";
import Xendit from "../images/xendit.webp";
const OfficialPartners = () => {
  const {
    t
  } = useTranslation("components");
  const isMobile = useMediaQuery({
    maxWidth: 1281
  });
  return <Container className='reku-new-theme relative flex flex-col items-center justify-center py-10 xl:py-20'>
            <h1 className='text-heading-sm xl:text-heading-lg text-text-neutral-strong dark:text-dark-text-neutral-strong mb-6 xl:mb-4'>
                {t("components:official_partner.title")}
            </h1>
            <Marquee className='gap-[32px] xl:gap-[64px] mr-[6%]' pauseOnHover play>
                <div className='flex items-center gap-[32px] xl:gap-[64px]'>
                    <Image src={Coinbase.src} height={isMobile ? 32.5 : 46.5} width={isMobile ? 214.9 : 307} className='filter grayscale' alt='coinbase-logo' />
                    <Image src={Skystar.src} height={isMobile ? 44.8 : 64} width={isMobile ? 92.6 : 132.3} className='filter grayscale' alt='skystar-logo' />
                    <Image src={ACV.src} height={isMobile ? 32.5 : 46.5} width={isMobile ? 191.5 : 273.5} className='filter grayscale' alt='ac-ventures-logo' />
                    <Image src={Xendit} height={isMobile ? 50.4 : 72} width={isMobile ? 117.1 : 167.3} className='filter grayscale' alt='xendit-logo' />
                    <Image src={Fireblocks} height={isMobile ? 28 : 40} width={isMobile ? 185.5 : 265} className='filter grayscale' alt='fireblocks-logo' />
                </div>
            </Marquee>
        </Container>;
};
export default OfficialPartners;