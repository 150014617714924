import { useTranslation } from "next-i18next";
import Link from "@components/Link";
import Container from "@components/v4/Container";
import { Icons } from "@reku-web/ui";
import AnnotationInfo from "../svgx/AnnotationInfo";
import BarLineChart from "../svgx/BarLineChart";
import GraduationHat from "../svgx/GraduationHat";
import NewspaperOutline from "../svgx/NewspaperOutline";
const getColors = (color: "yellow" | "green" | "blue" | "purple") => {
  switch (color) {
    case "yellow":
      return {
        card: "bg-background-subtle dark:bg-dark-background-solid-warning border-outline-color-warning/50 dark:border-dark-outline-color-warning/5 from-warning/50 to-background-subtle-warning dark:from-[#D87D00] dark:to-warning-25/0",
        icon: "text-text-color-warning dark:text-dark-text-color-warning"
      };
    case "blue":
      return {
        card: "bg-background-subtle dark:bg-dark-background-solid-info border-outline-color-info/50 dark:border-dark-outline-color-info/5 from-info/50 to-background-subtle-info dark:from-[#0000AF] dark:to-info-25/0",
        icon: "text-text-color-info dark:text-dark-text-color-info"
      };
    case "green":
      return {
        card: "bg-background-subtle dark:bg-dark-background-solid-success border-outline-color-success/50 dark:border-dark-outline-color-success/5 from-success/50 to-background-subtle-success dark:from-[#005300] dark:to-success-25/0",
        icon: "text-text-color-success dark:text-dark-text-color-success"
      };
    case "purple":
      return {
        card: "bg-background-subtle dark:bg-dark-background-solid-purple border-outline-color-purple/50 dark:border-dark-outline-color-purple/5 from-purple/50 to-background-subtle-purple dark:from-[#150081] dark:to-purple-25/0",
        icon: "text-text-color-purple dark:text-dark-text-color-purple"
      };
  }
};
interface LearningHubCardProps {
  title: string;
  description: string;
  color: "yellow" | "green" | "blue" | "purple";
  link: string;
  icon: React.ReactNode;
}
const LearningHubCard = ({
  title,
  description,
  color,
  link,
  icon
}: LearningHubCardProps) => <div className={`relative flex flex-col justify-between min-w-[293px] h-[280px] p-6 border rounded-lg bg-gradient-to-t ${getColors(color)?.card}`}>
        <div className='flex gap-2 items-center justify-between'>
            <span className='text-heading-lg text-text-neutral-main dark:text-dark-text-neutral-main'>{title}</span>
            <Link href={link} className='grid place-items-center p-2 bg-dark-actions-grey-active rounded-sm'>
                <Icons icon='ArrowRight' className={`w-4 h- 4 xl:w-6 xl:h-6 ${getColors(color)?.icon}`} />
            </Link>
        </div>
        <p className='relative z-[2] text-body-lg text-text-neutral-main dark:text-dark-text-neutral-main max-w-[200px]'>
            {description}
        </p>

        <div className='absolute bottom-0 right-0 mr-2 mb-4  z-[1]'>{icon}</div>
    </div>;
const LearningHub = () => {
  const {
    t
  } = useTranslation("home");
  const lists: LearningHubCardProps[] = [{
    title: t("learning_hub.information.title"),
    description: t("learning_hub.information.description"),
    link: "/information",
    color: "yellow",
    icon: <AnnotationInfo />
  }, {
    title: t("learning_hub.analisis.title"),
    description: t("learning_hub.analisis.description"),
    link: "/analysis",
    color: "blue",
    icon: <BarLineChart />
  }, {
    title: t("learning_hub.campus.title"),
    description: t("learning_hub.campus.description"),
    link: "/campus",
    color: "green",
    icon: <GraduationHat />
  }];
  return <section className='relative bg-background-neutral-main dark:bg-dark-background-neutral-main'>
            <Container className='flex flex-col xl:flex-row items-center justify-center xl:justify-between gap-8 xl:gap-[60px] py-10 xl:py-20'>
                <div className='text-center xl:text-left space-y-2 xl:space-y-3 max-w-[336px] md:max-w-[400px] xl:max-w-[610px] flex-1'>
                    <h1 className='text-heading-md xl:text-heading-xl text-text-neutral-strong dark:text-dark-text-neutral-strong'>
                        {t("learning_hub.title")}
                    </h1>
                </div>
                <div className='grid grid-cols-[repeat(4,_1fr)] xl:grid-cols-2 gap-3 xl:gap-6 w-full xl:w-auto overflow-auto scrollbar-none'>
                    {lists.map(item => <LearningHubCard key={item.title} {...item} />)}
                </div>
            </Container>
        </section>;
};
export default LearningHub;