import { useTranslation } from "next-i18next";
import Marquee from "react-fast-marquee";
import Link from "@components/Link";
import Container from "@components/v4/Container";
import { Button, Icons } from "@reku-web/ui";
type Color = "white" | "black" | "teal" | "purple";
interface TestimoniCardProps {
  description: string;
  user: string;
  color?: Color;
}
const getBGColor = (color?: Color) => {
  switch (color) {
    case "purple":
      return "bg-background-subtle-purple dark:bg-dark-background-subtle-purple";
    case "teal":
      return "bg-background-subtle-teal dark:bg-dark-background-subtle-teal";
    case "black":
      return "bg-background-neutral-inverse dark:bg-dark-background-card-main";
    case "white":
      return "bg-background-card-main dark:bg-dark-background-neutral-inverse";
    default:
      return "bg-background-subtle-purple dark:bg-dark-background-subtle-purple";
  }
};
const getColor = (color?: Color) => {
  if (color === "black") {
    return {
      text: "text-text-neutral-onImageStrong dark:text-dark-text-neutral-main",
      border: "border-dark-text-neutral-onImageStrong dark:border-dark-outline-neutral-main"
    };
  }
  if (color === "white") {
    return {
      text: "text-text-neutral-main dark:text-dark-text-neutral-inverse",
      border: "border-outline-neutral-main dark:border-dark-text-neutral-inverse"
    };
  }
  return {
    text: "text-text-neutral-main dark:text-dark-text-neutral-main",
    border: "border-outline-neutral-main dark:border-dark-outline-neutral-main"
  };
};
const TestimoniCard = ({
  description,
  user,
  color
}: TestimoniCardProps) => <div className={`p-8 w-[240px] xl:w-[384px] h-fit rounded-lg ${getBGColor(color)}`}>
        <div className='flex gap-1'>
            <Icons icon='WatchlistStar' className='w-6 h-6 text-icon-color-teal' />
            <Icons icon='WatchlistStar' className='w-6 h-6 text-icon-color-teal' />
            <Icons icon='WatchlistStar' className='w-6 h-6 text-icon-color-teal' />
            <Icons icon='WatchlistStar' className='w-6 h-6 text-icon-color-teal' />
            <Icons icon='WatchlistStar' className='w-6 h-6 text-icon-color-teal' />
        </div>
        <p className={`py-4 text-body-md ${getColor(color).text}`}>{description}</p>
        <div className={`text-md font-bold xl:text-heading-xs border-t ${getColor(color).text} ${getColor(color).border} pt-3`}>
            {user}
        </div>
    </div>;
const Testimonies = () => {
  const {
    t
  } = useTranslation("home");
  const list1 = [{
    description: "Saya bisa renovasi rumah hingga beli mobil listrik setelah 3 tahun berinvestasi kripto di Reku. Saya pilih Reku karena tampilannya nyaman dan proses transaksinya cepat.",
    user: "Tony, Sobat Reku asal Sukabumi",
    color: "white"
  }, {
    description: "Aku awalnya tertarik pakai Reku karena setahuku Reku jadi yang pertama dapat izin staking dari Bappebti, jadi asetku aman. Berkat investasi di Reku, aku jadi bisa biayain kuliahku dan kedua adikku.",
    user: "Heksa, Sobat Reku asal Jakarta",
    color: "black"
  }, {
    description: "Suka pakai Reku karena sangat mudah dipahami. Terbantu banget juga sama grup Telegram Reku yang bikin gue bisa ketemu sesama trader dan tim Community Reku yang ngasih info yang gue butuhin.",
    user: "Giselle, Sobat Reku asal Jakarta",
    color: "teal"
  }, {
    description: "Pernah cobain beberapa platform jual-beli kripto yang lain, tapi menurut saya Reku paling menarik karena ada game Rekuiz dan fitur Staking-nya aman karena sudah langsung disertifikasi Bappebti.",
    user: "Ius, Sobat Reku asal Tangerang",
    color: "purple"
  }];
  const list2 = [{
    description: "Reku proses withdrawnya cepat, ada fitur Lightning buat pemula, enak dah pokoknya. Cuan investasi di Reku bisa sampai buat buka kedai kopi.",
    user: "Hary Marcello, Sobat Reku asal Majalengka",
    color: "white"
  }, {
    description: "Udah gonta-ganti platform kripto, akhirnya pilih Reku karena proses transaksinya sangat cepat dan trading fee-nya rendah. Berita kripto juga lengkap dari Ringkasan Reku, Reku Kampus, dan Reku Rekap.",
    user: "Jamal, Sobat Reku asal Cirebon",
    color: "black"
  }, {
    description: "Dikenalin Reku sama teman, langsung pindah pakai Reku karena lebih gampang dipakainya dan potongan withdrawnya nggak besar. Gara-gara cuan dari Reku, bisa biayain uang kuliah tahunan!",
    user: "Muhammad Fajar, Sobat Reku asal Aceh",
    color: "teal"
  }, {
    description: "Reku mantap, udah tampilannya simpel buat pemula, bisa transfer antar pengguna exchange global secara free lagi! Artikel-artikel dari Learning Hub juga sangat membantu pemula seperti gue.",
    user: "Fikri, Sobat Reku asal Medan",
    color: "purple"
  }, {
    description: "Reku langsung jadi platform investasi pertama yang aku pilih karena banyak kelebihannya kayak fee rendah, user interface-nya bagus, dan ada fitur Investment Insight yang bermanfaat banget buat kita bisa liat profit & loss tiap koin secara langsung.",
    user: "Darmawan Mahesa, Sobat Reku asal Semarang",
    color: "teal"
  }];
  return <Container className='py-10 xl:py-20 space-y-8 xl:space-y-20 px-0 relative bg-background-neutral-main dark:bg-dark-background-neutral-main'>
            <div className='mx-auto text-center max-w-[720px] px-3'>
                <h1 className='text-heading-md xl:text-heading-xl text-text-neutral-strong dark:text-dark-text-neutral-strong'>
                    {t("testimonies.title")}
                </h1>
                <p className='mt-2 text-md xl:text-lg font-semibold text-text-neutral-subtle dark:text-dark-text-neutral-subtle'>
                    {t("testimonies.subtitle")}
                </p>
                <Link href='https://t.me/reku_id' target='_blank' className='mx-auto mt-4 xl:mt-6 !w-fit'>
                    <Button className='min-w-[170px]'>{t("testimonies.join_now")}</Button>
                </Link>
            </div>
            <div className='flex flex-col relative'>
                <div className='hidden xl:block absolute left-0 top-0 h-full w-[100px] bg-gradient-to-r from-white/70 dark:from-dark-background-neutral-main/70 z-[2]' />
                <div className='hidden xl:block absolute right-0 top-0 h-full w-[100px] bg-gradient-to-l from-white/70 dark:from-dark-background-neutral-main/70 z-[2]' />
                <Marquee className='gap-3 xl:gap-8' pauseOnHover>
                    <div className='flex gap-3 xl:gap-8 mb-3 xl:mb-8'>
                        {list1.map(({
            description,
            user,
            color
          }) => <TestimoniCard key={description} description={description} user={user} color={color as Color} />)}
                    </div>
                </Marquee>
                <Marquee className='gap-3 xl:gap-8' pauseOnHover>
                    <div className='flex gap-3 xl:gap-8 -translate-x-[192px]'>
                        {list2.map(({
            description,
            user,
            color
          }) => <TestimoniCard key={description} description={description} user={user} color={color as Color} />)}
                    </div>
                </Marquee>
            </div>
        </Container>;
};
export default Testimonies;