import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import classNames from "classnames";
interface CarouselProps {
  variant?: "teal" | "purple";
  children: React.ReactNode;
  className?: string;
}
const Carousel = ({
  variant = "teal",
  className,
  children
}: CarouselProps) => {
  return <Swiper slidesPerView='auto' pagination={{
    clickable: true,
    bulletActiveClass: `${variant === "teal" ? "!bg-teal" : "!bg-purple"} !opacity-100 !w-4 !h-[6px] !rounded-[3px]`,
    renderBullet: function (_, className) {
      return `<span class="${className}" style="width: 6px; height: 6px;"></span>`;
    }
  }} modules={[Pagination]} className={classNames("min-h-[200px]", className)}>
            {children}
        </Swiper>;
};
export { SwiperSlide as Slide };
export default Carousel;