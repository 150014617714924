export const RadialIllustration = () => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='956' height='956' className='w-[496px] h-[496px] xl:w-[956px] xl:h-[956px]' fill='none' viewBox='0 0 956 956'>
            <g fillOpacity='0.2' opacity='0.4'>
                <circle cx='478' cy='478' r='478' fill='url(#paint0_linear_2845_52624)' opacity='0.5'></circle>
                <ellipse cx='479' cy='478' fill='url(#paint1_linear_2845_52624)' opacity='0.5' rx='368' ry='369'></ellipse>
                <ellipse cx='478.5' cy='478' fill='url(#paint2_linear_2845_52624)' opacity='0.5' rx='270.5' ry='271'></ellipse>
            </g>
            <defs>
                <linearGradient id='paint0_linear_2845_52624' x1='478' x2='478' y1='0' y2='672.887' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#52D3D8'></stop>
                    <stop offset='1' stopColor='#24221F'></stop>
                </linearGradient>
                <linearGradient id='paint1_linear_2845_52624' x1='479' x2='479.66' y1='109' y2='761.339' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#52D3D8'></stop>
                    <stop offset='1' stopColor='#24221F'></stop>
                </linearGradient>
                <linearGradient id='paint2_linear_2845_52624' x1='478.5' x2='478.984' y1='207' y2='686.089' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#52D3D8'></stop>
                    <stop offset='1' stopColor='#24221F'></stop>
                </linearGradient>
            </defs>
        </svg>;
};
export const CrackIllustration = () => {
  return <>
            <svg xmlns='http://www.w3.org/2000/svg' width='350' height='418' className='xl:hidden' fill='none' viewBox='0 0 350 418'>
                <g filter='url(#filter0_f_1526_99556)'>
                    <path fill='url(#paint0_linear_1526_99556)' d='M443.956 260.253l16.919 61.034C478.535 384.995 430.619 448 364.509 448H226.054c-33.808 0-64.341-20.214-77.543-51.339a84.259 84.259 0 01-3.878-11.315L103.795 231.25a100.017 100.017 0 01-3.275-29.128l3.206-91.248C104.723 82.493 128.019 60 156.418 60c5.537 0 11.039.872 16.304 2.584l109.764 35.692a99.989 99.989 0 0138.874 23.486l96.027 93.591a100 100 0 0126.569 44.9z'></path>
                </g>
                <defs>
                    <filter id='filter0_f_1526_99556' width='564.107' height='588' x='0.459' y='-40' colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse'>
                        <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
                        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
                        <feGaussianBlur result='effect1_foregroundBlur_1526_99556' stdDeviation='50'></feGaussianBlur>
                    </filter>
                    <linearGradient id='paint0_linear_1526_99556' x1='176.26' x2='445.015' y1='460.736' y2='235.14' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#6F4CB3'></stop>
                        <stop offset='0.145' stopColor='#2FAFB3' stopOpacity='0.825'></stop>
                        <stop offset='0.83' stopColor='#90D3D5' stopOpacity='0'></stop>
                    </linearGradient>
                </defs>
            </svg>
            <svg xmlns='http://www.w3.org/2000/svg' width='1144' height='591' className='hidden xl:block' fill='none' viewBox='0 0 1144 591'>
                <g style={{
        mixBlendMode: "screen"
      }} filter='url(#filter0_f_1526_101938)'>
                    <path fill='url(#paint0_linear_1526_101938)' d='M175.461 216.688L111.108 87.621C77.959 21.138 126.311-57 200.601-57h616.354a100.001 100.001 0 0179.503 39.342l24.955 32.708a100.061 100.061 0 0110.766 17.628l101.651 213.25a99.865 99.865 0 019.53 49.335l-6.44 102.043C1033.59 449.981 989.894 491 937.115 491h-47.703a100.03 100.03 0 01-17.79-1.595l-330.779-59.799a99.979 99.979 0 01-29.851-10.483l-293.68-159.134a99.989 99.989 0 01-41.851-43.301z'></path>
                </g>
                <defs>
                    <filter id='filter0_f_1526_101938' width='1143.09' height='748' x='0.472' y='-157' colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse'>
                        <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
                        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
                        <feGaussianBlur result='effect1_foregroundBlur_1526_101938' stdDeviation='50'></feGaussianBlur>
                    </filter>
                    <linearGradient id='paint0_linear_1526_101938' x1='851.269' x2='496.403' y1='-74.988' y2='460.8' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#6F4CB3'></stop>
                        <stop offset='0' stopColor='#6F4CB3'></stop>
                        <stop offset='0' stopColor='#2FAFB3' stopOpacity='0.825'></stop>
                        <stop offset='0.695' stopColor='#90D3D5' stopOpacity='0'></stop>
                    </linearGradient>
                </defs>
            </svg>
        </>;
};
export const BitcoinBubble = () => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' className='w-[50px] h-[50px] xl:w-[88px] xl:h-[88px]' fill='none' viewBox='0 0 88 88'>
            <rect width='88' height='88' fill='url(#paint0_radial_1585_33079)' rx='44'></rect>
            <path fill='#F7931A' fillRule='evenodd' d='M58.114 39.44c.637-4.258-2.605-6.547-7.039-8.074l1.438-5.768-3.511-.875-1.4 5.616c-.923-.23-1.871-.447-2.814-.662l1.41-5.654-3.509-.875-1.439 5.767c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.21-.934 3.751s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.572c.098.025.225.061.365.117l-.102-.025-.269-.066-2.296 9.206c-.174.431-.615 1.08-1.61.834.036.05-2.552-.637-2.552-.637l-1.743 4.02 4.57 1.138c.5.126.995.255 1.484.382.342.09.682.178 1.02.264l-1.454 5.835 3.508.875 1.439-5.773c.958.26 1.888.5 2.798.727L39.508 62.8l3.511.875 1.453-5.824c5.988 1.134 10.49.677 12.386-4.74 1.527-4.36-.076-6.875-3.226-8.515 2.294-.53 4.022-2.038 4.483-5.156h-.001zM50.09 50.692c-.996 4-7.258 2.347-10.123 1.59a50.65 50.65 0 00-.684-.178l1.928-7.73c.24.06.532.126.863.2 2.964.665 9.036 2.028 8.016 6.118zm-7.421-9.705c2.388.637 7.6 2.028 8.507-1.608.927-3.72-4.137-4.84-6.61-5.388a30.639 30.639 0 01-.725-.166l-1.748 7.01c.165.042.359.094.576.152z' clipRule='evenodd'></path>
            <defs>
                <radialGradient id='paint0_radial_1585_33079' cx='0' cy='0' r='1' gradientTransform='matrix(0 44 -44 0 44 44)' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.465' stopColor='#fff' stopOpacity='0'></stop>
                    <stop offset='0.965' stopColor='#fff' stopOpacity='0.11'></stop>
                    <stop offset='1' stopColor='#F2F2F2' stopOpacity='0.29'></stop>
                </radialGradient>
            </defs>
        </svg>;
};
export const SushiSwapBubble = () => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' className='w-[50px] h-[50px] xl:w-[88px] xl:h-[88px]' fill='none' viewBox='0 0 88 88'>
            <rect width='88' height='88' fill='url(#paint0_radial_1585_33087)' rx='44'></rect>
            <path fill='url(#paint1_linear_1585_33087)' d='M31.342 27.466l32.924 22.23L56.123 61.6 23.2 39.194l8.143-11.728z'></path>
            <path fill='url(#paint2_linear_1585_33087)' d='M64.623 49.742c-2.814 4.108-12.312 2.5-21.282-3.93-9.146-6.251-14.071-14.646-11.433-18.754 2.814-4.108 12.313-2.501 21.283 3.93 9.146 6.072 14.07 14.646 11.432 18.754z'></path>
            <path fill='url(#paint3_linear_1585_33087)' d='M56.087 61.49c-2.823 4.081-12.352 2.484-21.35-3.904-9-6.388-14.117-14.55-11.294-18.81 2.824-4.081 12.352-2.484 21.351 3.904 8.999 6.389 13.94 14.729 11.293 18.81z'></path>
            <path fill='#0E0F23' d='M64.135 49.556L55.988 61.55c-2.833 4.056-12.397 2.293-21.43-3.88a53.32 53.32 0 01-4.958-3.88c1.24-.176 2.833-.882 4.427-2.645 2.834-2.998 4.25-3.704 5.49-3.527 1.24 0 2.657 1.234 4.96 4.232 2.302 2.998 5.49 3.88 7.438 2.293.177-.177.354-.177.531-.353 1.594-1.234 2.126-1.764 5.137-7.407.708-1.41 3.187-3.703 6.552-2.645.886 2.292.886 4.232 0 5.82z'></path>
            <path fill='#fff' fillRule='evenodd' d='M62.639 49.114c-2.464 3.541-11.088 1.77-19.36-4.073-8.448-5.843-13.375-13.28-10.911-16.822 2.463-3.541 11.087-1.77 19.359 4.073 8.272 5.843 13.2 13.28 10.912 16.822zm-7.744-5.312c-1.232 1.77-5.456.885-9.68-1.948-4.048-2.833-6.512-6.552-5.28-8.322 1.232-1.771 5.456-.886 9.68 1.947 4.048 2.833 6.512 6.552 5.28 8.323z' clipRule='evenodd'></path>
            <path fill='#fff' d='M30.607 30.875c0-.147-.16-.295-.32-.147-.16.147-.32.147-.32.295.16.444.32.739.32 1.034 0 .148.16.296.32.148.16 0 .32-.148.16-.295 0-.296 0-.591-.16-1.035zM31.721 33.585c0-.178-.174-.356-.348-.178s-.174.178-.174.356c1.914 4.453 5.915 9.262 11.134 12.824.174.178.348 0 .522 0 .174-.178 0-.356 0-.534-5.393-3.562-9.22-8.193-11.134-12.468zM52.861 51.253c-.164 0-.328 0-.328.16s0 .32.164.32c.493.16 1.149.32 1.641.48.164 0 .328 0 .328-.16s0-.32-.164-.32c-.492-.16-1.148-.32-1.64-.48zM56.075 51.833c-.171 0-.343.16-.343.32s.172.32.343.32c1.372.16 2.915.32 4.115.16.171 0 .342-.16.342-.32s-.171-.32-.342-.32c-1.372.16-2.743 0-4.115-.16z'></path>
            <defs>
                <radialGradient id='paint0_radial_1585_33087' cx='0' cy='0' r='1' gradientTransform='matrix(0 44 -44 0 44 44)' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.465' stopColor='#fff' stopOpacity='0'></stop>
                    <stop offset='0.965' stopColor='#fff' stopOpacity='0.11'></stop>
                    <stop offset='1' stopColor='#F2F2F2' stopOpacity='0.29'></stop>
                </radialGradient>
                <linearGradient id='paint1_linear_1585_33087' x1='24.455' x2='29.046' y1='27.019' y2='49.585' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#03B8FF'></stop>
                    <stop offset='1' stopColor='#FA52A0'></stop>
                </linearGradient>
                <linearGradient id='paint2_linear_1585_33087' x1='29.465' x2='33.661' y1='24.837' y2='47.879' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#03B8FF'></stop>
                    <stop offset='1' stopColor='#FA52A0'></stop>
                </linearGradient>
                <linearGradient id='paint3_linear_1585_33087' x1='18.368' x2='22.579' y1='28.018' y2='50.917' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#03B8FF'></stop>
                    <stop offset='1' stopColor='#FA52A0'></stop>
                </linearGradient>
            </defs>
        </svg>;
};
export const TeslaBubble = () => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' className='w-[50px] h-[50px] xl:w-[88px] xl:h-[88px]' fill='none' viewBox='0 0 88 88'>
            <rect width='88' height='88' fill='url(#paint0_radial_1585_33103)' rx='44'></rect>
            <path fill='#fff' d='M44.093 68l5.9-33.187c5.625 0 7.4.617 7.655 3.134 0 0 3.773-1.407 5.677-4.264-7.427-3.441-14.888-3.596-14.888-3.596l-4.354 5.303h.01l-4.354-5.304s-7.462.155-14.887 3.596c1.9 2.858 5.676 4.265 5.676 4.265.257-2.518 2.03-3.135 7.616-3.139L44.093 68z'></path>
            <path fill='#fff' d='M44.09 28.555c6.003-.046 12.874.929 19.908 3.994.94-1.692 1.182-2.44 1.182-2.44-7.69-3.042-14.89-4.083-21.09-4.109-6.201.026-13.402 1.067-21.09 4.11 0 0 .343.92 1.181 2.44 7.033-3.066 13.905-4.041 19.908-3.995h.001z'></path>
            <defs>
                <radialGradient id='paint0_radial_1585_33103' cx='0' cy='0' r='1' gradientTransform='matrix(0 44 -44 0 44 44)' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.465' stopColor='#fff' stopOpacity='0'></stop>
                    <stop offset='0.965' stopColor='#fff' stopOpacity='0.11'></stop>
                    <stop offset='1' stopColor='#F2F2F2' stopOpacity='0.29'></stop>
                </radialGradient>
            </defs>
        </svg>;
};
export const GoogleBubble = () => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' className='w-[50px] h-[50px] xl:w-[88px] xl:h-[88px]' fill='none' viewBox='0 0 88 88'>
            <rect width='88' height='88' fill='url(#paint0_radial_1585_33095)' rx='44'></rect>
            <rect width='64' height='64' x='12' y='12' stroke='#000' strokeOpacity='0.1' strokeWidth='0.5' rx='32'></rect>
            <g clipPath='url(#clip0_1585_33095)'>
                <path fill='#4285F4' d='M65.588 44.316c0-1.803-.146-3.12-.463-4.485H44.528v8.142h12.09c-.244 2.023-1.56 5.07-4.485 7.117l-.04.272 6.511 5.045.452.046c4.143-3.827 6.532-9.458 6.532-16.137z'></path>
                <path fill='#34A853' d='M44.528 65.766c5.923 0 10.895-1.95 14.527-5.313l-6.922-5.363c-1.853 1.292-4.339 2.194-7.605 2.194-5.801 0-10.725-3.827-12.48-9.116l-.257.022-6.772 5.24-.088.246c3.607 7.167 11.017 12.09 19.597 12.09z'></path>
                <path fill='#FBBC05' d='M32.048 48.168a13.505 13.505 0 01-.731-4.339c0-1.511.268-2.974.707-4.339l-.013-.29-6.856-5.325-.224.107a21.957 21.957 0 00-2.34 9.847c0 3.534.853 6.874 2.34 9.847l7.117-5.508z'></path>
                <path fill='#EB4335' d='M44.528 30.374c4.12 0 6.898 1.78 8.482 3.267l6.191-6.045c-3.802-3.535-8.75-5.704-14.673-5.704-8.58 0-15.99 4.924-19.597 12.09l7.093 5.508c1.78-5.289 6.703-9.116 12.504-9.116z'></path>
            </g>
            <defs>
                <radialGradient id='paint0_radial_1585_33095' cx='0' cy='0' r='1' gradientTransform='matrix(0 44 -44 0 44 44)' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.465' stopColor='#fff' stopOpacity='0'></stop>
                    <stop offset='0.965' stopColor='#fff' stopOpacity='0.11'></stop>
                    <stop offset='1' stopColor='#F2F2F2' stopOpacity='0.29'></stop>
                </radialGradient>
                <clipPath id='clip0_1585_33095'>
                    <path fill='#fff' d='M0 0H42.997V44H0z' transform='translate(22.59 21.83)'></path>
                </clipPath>
            </defs>
        </svg>;
};