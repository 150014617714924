import React, { memo, useMemo } from "react";
import NextHead from "next/head";
import { useRouter } from "next/router";
import { NextSeo, SoftwareAppJsonLd } from "next-seo";
import { BRAND_LOGO_URL, PUBLIC_URL } from "@config/config";
import { defaultLanguage, languages } from "@config/i18n";
import useOnPageSeo from "@hooks/useOnPageSeo";
const siteURL = `${PUBLIC_URL || "https://reku.id"}`;
interface HeadProps extends React.PropsWithChildren<{}> {
  title?: string;
  titleSuffix?: string;
  description?: string;
  image?: string;
  variables?: Record<string, string>;
  disableSeoConfig?: boolean;
}
const Head: React.FC<HeadProps> = ({
  title: titleProp,
  titleSuffix,
  description: descriptionProp,
  children,
  image,
  variables,
  disableSeoConfig = false
}: HeadProps) => {
  const router = useRouter();
  const onPageSeo = useOnPageSeo({
    variables,
    disable: disableSeoConfig
  });
  const locale = useMemo(() => {
    return (router.locale ?? defaultLanguage) as "en" | "id";
  }, [router.locale]);
  const title = useMemo(() => {
    if (onPageSeo?.title[locale]) {
      return onPageSeo.title[locale];
    }
    if (typeof titleSuffix !== "undefined") {
      if (titleSuffix === "") {
        return titleProp;
      }
      return `${titleProp} ${titleSuffix}`;
    }
    return `${titleProp} - Reku`;
  }, [onPageSeo?.title, locale, titleProp, titleSuffix]);
  const description = useMemo(() => {
    if (onPageSeo?.metaDescription[locale]) {
      return onPageSeo.metaDescription[locale];
    }
    return descriptionProp;
  }, [onPageSeo?.metaDescription, locale, descriptionProp]);
  const canonicalUrl = useMemo(() => {
    if (onPageSeo?.canonical_url) {
      if (!onPageSeo.canonical_url.includes("http")) {
        return `${siteURL}${onPageSeo.canonical_url}`;
      }
      return onPageSeo.canonical_url;
    }
    return undefined;
  }, [onPageSeo?.canonical_url]);
  const hrefLang = useMemo(() => {
    return languages.map(lang => {
      const subPath = lang.code !== defaultLanguage ? `/${lang.code}` : "";
      const href = `${siteURL}${subPath}${router.asPath}`;
      return {
        lang: lang.code,
        href
      };
    });
  }, [router.asPath]);
  const childrenArray = React.Children.toArray(children);
  const metaDescription = childrenArray.find(child => {
    return React.isValidElement(child) && child.type === "meta" && child.props.name === "description";
  });
  const isMetaDescriptionExist = !!metaDescription;
  return <>
            <NextHead>{children}</NextHead>

            <NextSeo title={title} description={!isMetaDescriptionExist ? description : undefined} nofollow={onPageSeo?.noFollow} noindex={onPageSeo?.noIndex} canonical={canonicalUrl} languageAlternates={hrefLang.map(lang => ({
      hrefLang: lang.lang,
      href: lang.href
    }))} openGraph={{
      type: "website",
      site_name: "Reku",
      title,
      description,
      images: image ? [{
        url: image
      }] : undefined
    }} additionalMetaTags={[{
      name: "viewport",
      content: "width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=5, minimum-scale=1"
    }, {
      name: "msapplication-TileColor",
      content: "#ffffff"
    }, {
      name: "msapplication-TileImage",
      content: "https://images.reku.id/favicon/ms-icon-144x144.png"
    }]} twitter={{
      cardType: "summary"
    }} additionalLinkTags={[{
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: "https://images.reku.id/favicon/apple-icon-57x57.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: "https://images.reku.id/favicon/apple-icon-60x60.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: "https://images.reku.id/favicon/apple-icon-72x72.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: "https://images.reku.id/favicon/apple-icon-76x76.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: "https://images.reku.id/favicon/apple-icon-114x114.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: "https://images.reku.id/favicon/apple-icon-120x120.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: "https://images.reku.id/favicon/apple-icon-144x144.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "https://images.reku.id/favicon/apple-icon-152x152.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "https://images.reku.id/favicon/apple-icon-180x180.png"
    }, {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "https://images.reku.id/favicon/android-icon-192x192.png"
    }, {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "https://images.reku.id/favicon/favicon-32x32.png"
    }, {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "https://images.reku.id/favicon/favicon-96x96.png"
    }, {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "https://images.reku.id/favicon/favicon-16x16.png"
    }]} />
            <SoftwareAppJsonLd name='Reku: Beli & Investasi Crypto' url='https://play.google.com/store/apps/details?id=com.rekeningku' operatingSystem='Android' applicationCategory='Finance' aggregateRating={{
      ratingValue: "4.7",
      ratingCount: "33800"
    }} price='0' priceCurrency='IDR' inLanguage={[{
      name: "Indonesia",
      alternateName: "id"
    }, {
      name: "English",
      alternateName: "en"
    }]} />
        </>;
};
Head.defaultProps = {
  title: "Harga Bitcoin & Jual Beli Bitcoin Indonesia",
  description: "Jual beli Bitcoin Indonesia, Harga Bitcoin, kripto, dan aset kripto lainnya dengan rupiah dan biaya terendah",
  titleSuffix: "",
  image: BRAND_LOGO_URL,
  variables: undefined
};
export default memo(Head);