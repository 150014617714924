import { useTranslation } from "next-i18next";
import Container from "@components/v4/Container";
import { Icons, IconsProps } from "@reku-web/ui";
import Carousel, { Slide } from "@components/Carousel";
const Service = () => {
  const {
    t
  } = useTranslation("home");
  const services = [{
    id: 1,
    icon: "Bank",
    title: t("service.fee.title"),
    subtitle: t("service.fee.subtitle")
  }, {
    id: 2,
    icon: "Security",
    title: t("service.license.title"),
    subtitle: t("service.license.subtitle")
  }, {
    id: 3,
    icon: "MessageChatCirlce",
    title: t("service.live_chat.title"),
    subtitle: t("service.live_chat.subtitle")
  }];
  const renderedDesktopList = services.map(({
    id,
    icon,
    title,
    subtitle
  }) => <div className='flex flex-col xl:flex-1 gap-1 items-center xl:items-start text-center xl:text-left' key={id}>
            <div className={`h-12 w-12 xl:h-14 xl:w-14 grid place-content-center bg-background-subtle-teal dark:bg-dark-background-subtle-teal text-icon-color-teal l p-2.5 shadow-card dark:shadow-dark-card rounded-md border-[6px] ${"border-teal-25 dark:border-teal-700"}`}>
                <Icons icon={icon as IconsProps} className='w-6 h-6 xl:w-7 xl:h-7' />
            </div>
            <p className='text-heading-sm xl:text-heading-lg text-text-neutral-strong dark:text-dark-text-neutral-strong mt-[2px]'>
                {title}
            </p>
            <p className='text-body-sm xl:text-body-lg text-text-neutral-main dark:text-dark-text-neutral-main'>
                {subtitle}
            </p>
        </div>);
  const renderedMobileList = services.map(({
    id,
    icon,
    title,
    subtitle
  }) => <Slide key={id} className='!w-fit !pr-3'>
            <div className='w-[196px] flex flex-col xl:flex-1 gap-1'>
                <div className={`h-12 w-12 xl:h-14 xl:w-14 grid place-content-center bg-background-subtle-teal dark:bg-dark-background-subtle-teal text-icon-color-teal l p-2.5 shadow-card dark:shadow-dark-card rounded-md border-[6px] ${"border-teal-25 dark:border-teal-700"}`}>
                    <Icons icon={icon as IconsProps} className='w-6 h-6 xl:w-7 xl:h-7' />
                </div>
                <p className='text-heading-sm xl:text-heading-lg text-text-neutral-strong dark:text-dark-text-neutral-strong mt-[2px]'>
                    {title}
                </p>
                <p className='text-body-sm xl:text-body-lg text-text-neutral-main dark:text-dark-text-neutral-main'>
                    {subtitle}
                </p>
            </div>
        </Slide>);
  return <section className='bg-background-neutral-main dark:bg-dark-background-neutral-main'>
            <Container className='py-10 xl:py-[60px]'>
                <div className='hidden xl:grid xl:grid-cols-3 gap-6 xl:gap-10'>{renderedDesktopList}</div>
                <div className='xl:hidden'>
                    <Carousel className='!min-h-[230px]'>{renderedMobileList}</Carousel>
                </div>
            </Container>
        </section>;
};
export default Service;