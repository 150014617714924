import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetStaticProps } from "next";
import { defaultLanguage } from "@config/i18n";
import Home from "@modules/Home";
export const getStaticProps: GetStaticProps = async ({
  locale
}) => ({
  props: {
    ...(await serverSideTranslations(locale || defaultLanguage, ["components", "common", "pages", "home"]))
  },
  revalidate: 3600
});
export default Home;