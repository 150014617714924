import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useMediaQuery } from "react-responsive";
import Container from "@components/v4/Container";
import { Button } from "@reku-web/ui";
import useUserMode from "@hooks/useUserMode";
import LogoBappebti from "../images/logo-bappebti-white.webp";
import LogoKominfo from "../images/logo-kominfo-white.webp";
import HeroMockup from "../images/hero-mockup.webp";
import { CrackIllustration, BitcoinBubble, GoogleBubble, RadialIllustration, SushiSwapBubble, TeslaBubble } from "../svgx/HeroIllustration";
import Link from "@components/Link";
import useOneLink from "@hooks/useOneLink";
const Hero = () => {
  const {
    t
  } = useTranslation("home");
  const oneLink = useOneLink("?af_xp=custom&pid=Website&c=Web-Home-Button&af_click_lookback=7d&af_cost_value=0&af_cost_currency=IDR");
  const {
    getTradeModeURL
  } = useUserMode();
  const isMobile = useMediaQuery({
    maxWidth: 1279
  });
  const bubbles = [{
    id: 1,
    position: "top-[69px] left-[10px] xl:top-[122px] xl:left-[-20px]",
    svg: <BitcoinBubble />
  }, {
    id: 2,
    position: "top-[38px] right-[-5px] xl:top-[16px] xl:right-0",
    svg: <TeslaBubble />
  }, {
    id: 3,
    position: "top-[206px] left-[36px] xl:top-[328px] xl:left-[44px]",
    svg: <GoogleBubble />
  }, {
    id: 4,
    position: "top-[152px] right-[-40px] xl:top-[216px] xl:right-[-85px]",
    svg: <SushiSwapBubble />
  }];
  return <section className='relative bg-dark-background-neutral-subtle -mt-[48px] xl:-mt-[72px] pt-[72px] overflow-hidden'>
            <Container>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:max-w-[600px] relative z-[2] space-y-4 xl:space-y-6 pt-[60px] pb-[34px] xl:py-[120px] text-center xl:text-left'>
                        <h1 className='text-heading-lg xl:text-[56px] xl:font-extrabold xl:leading-[72px] xl:tracking-[-1.6px] text-text-neutral-onImageStrong'>
                            {t("hero_section.title")}
                        </h1>
                        <p className='text-body-md xl:text-[20px] xl:leading-8 text-text-neutral-onImageSubtle'>
                            {t("hero_section.subtitle")}
                        </p>
                        <div className='flex gap-3 !mt-8 xl:!mt-6'>
                            <Link href={!isMobile ? getTradeModeURL("BTC") : "/trade/BTC-IDR"} external={isMobile} className='!w-full xl:!w-auto'>
                                <Button size='lg' variant='gray' className='flex items-center justify-center bg-dark-actions-grey-active text-dark-text-neutral-inverse hover:!bg-background-neutral-strong !rounded-md h-12 w-full xl:w-auto xl:h-14 xl:p-4 xl:text-lg'>
                                    {t("invest_now")}
                                </Button>
                            </Link>
                            <Link href='https://reku.onelink.me/5mL7/web' target='_blank' className='xl:!hidden !w-full'>
                                <Button size='lg' variant='grayOutline' className='flex items-center justify-center !border-dark-actions-grey-active hover:!bg-dark-background-neutral-strong !text-dark-actions-grey-active !rounded-md h-12 w-full xl:w-auto xl:h-14 xl:p-4 xl:text-lg'>
                                    Download
                                </Button>
                            </Link>
                            <Link href={oneLink} className='!hidden xl:!block' target='_blank'>
                                <Button size='lg' variant='grayOutline' className='hidden xl:flex items-center justify-center !border-dark-actions-grey-active hover:!bg-dark-background-neutral-strong !text-dark-actions-grey-active !rounded-md h-12 w-full xl:w-auto xl:h-14 xl:p-4 xl:text-lg'>
                                    Download
                                </Button>
                            </Link>
                        </div>
                        <div className='flex flex-col xl:flex-row items-center gap-2 xl:gap-3 !mt-8 xl:!mt-[72px]'>
                            <span className='text-body-md xl:text-body-lg text-icon-neutral-onImageSubtle'>
                                {t("hero_section.registered_regulated")}
                            </span>
                            <div className='flex items-center gap-3 xl:gap-4'>
                                <Image src={LogoBappebti.src} alt='logo bappebti' width={128} height={32} className='w-[112px] h-[28px] xl:w-[128px] xl:h-[32px]' />
                                <Image src={LogoKominfo.src} alt='logo kominfo' width={40} height={40} className='w-[32px] h-[32px] xl:w-[40px] xl:h-[40px]' />
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 flex xl:items-end justify-center'>
                        <div className='relative translate-x-[-42px] z-[1]'>
                            <Image src={HeroMockup.src} priority quality={100} alt='reku app trading screen' width={526.71} height={724.87} className='relative z-[1] w-[315.37px] h-[434.02px] xl:w-[526.71px] xl:h-[724.87px]' />
                            {bubbles.map(({
              id,
              position,
              svg
            }) => <div key={id} className={`absolute ${position}`}>
                                    {svg}
                                </div>)}
                        </div>
                    </div>
                    <div className='absolute bottom-[-110px] right-[-50px] xl:bottom-[-300px] xl:right-[-113px] [@media(min-width:1700px)]:right-[5%]'>
                        <RadialIllustration />
                    </div>
                    <div className='absolute bottom-0 right-0 xl:bottom-[unset] xl:top-0 xl:right-[15%]'>
                        <CrackIllustration />
                    </div>
                </div>
            </Container>
        </section>;
};
export default Hero;